<template>
  <v-container fluid>
    <layout :back="true" />
    <div>
      <faq-card :show="showCard"></faq-card>
      <v-row class="ma-0 pa-o" justify-sm="center">
        <v-col
          cols="12"
          md="4"
          sm="6"
          class="pb-0 text-h5 text-center font-weight-medium"
          >{{ $t("contact.us") }}</v-col
        >
      </v-row>
      <v-row class="ma-0 pa-o" justify-sm="center">
        <v-col cols="12" sm="8" md="7" class="text-center text-subtitle-2">{{
          $t("contact.explain")
        }}</v-col>
      </v-row>
    </div>

    <v-form ref="form" v-model="formValid">
      <v-row style="" class="ma-0 mt-8" justify="center" v-if="!isLogged">
        <v-col cols="12" sm="8" md="5">
          <v-text-field
            :label="$t('field.contact_email')"
            v-model="email"
            validate-on-blur
            :rules="!isLogged && emailRules"
          />
        </v-col>
      </v-row>
      <v-row style="" class="ma-0 mt-8" justify="center">
        <v-col cols="12" sm="8" md="5">
          <v-text-field
            :label="$t('contact.motivation')"
            v-model="subject"
            counter="100"
            maxlength="100"
            :rules="required"
          />
        </v-col>
      </v-row>
      <v-row style="" class="ma-0 mt-8" justify="center">
        <v-col cols="12" sm="8" md="5">
          <v-textarea
            auto-grow
            :label="$t('contact.query')"
            v-model="message"
            counter="500"
            maxlength="500"
            :rules="required"
          />
        </v-col>
      </v-row>
      <v-row class="mx-4 mb-4" justify="center">
        <v-col cols="8" md="2" sm="4" class="pa-0 mt-2 mr-0 text-center">
          <div>
            <v-btn
              color="secondary"
              class="text-capitalize"
              block
              large
              rounded
              :disabled="!formValid"
              @click="submitContactForm"
            >
              {{ $t("contact.button") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import CoreService from "@/services/CoreService.js";
import FaqCard from "@/components/shared/FaqCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Layout,
    FaqCard
  },
  computed: {
    ...mapGetters({ isLogged: "user/isLogged" }),
    ...mapGetters({ userInfo: "user/info" })
  },
  data() {
    return {
      formValid: false,
      email: null,
      showCard: false,
      subject: null,
      message: null,
      required: [v => !!v || this.$i18n.t("validation.required")],
      emailRules: [
        v => !!v || this.$i18n.t("validation.required"),
        v => /.+@.+/.test(v) || this.$i18n.t("validation.email_format")
      ]
    };
  },
  methods: {
    async submitContactForm() {
      if (this.email == null) {
        this.email = this.userInfo.email;
      }
      if (this.$refs.form.validate())
        try {
          await CoreService.postContactForm({
            email: this.email,
            subject: this.subject,
            message: this.message
          });
          this.$notify({
            title: this.$i18n.t("notification.contact"),
            text: this.$i18n.t("notification.contact"),
            type: "success"
          });
          this.$router.go(-1);
        } catch {
          this.$notify({
            title: this.$i18n.t("notification.contact_error"),
            text: this.$i18n.t("notification.contact_error"),
            type: "error"
          });
        }
    }
  },
  created() {
    setTimeout(() => {
      this.showCard = true;
    }, 200);
  }
};
</script>
